@import '../../../general/scss/settings/variables.scss';
@import '../../../general/scss/settings/functions.scss';
@import '../../../../node_modules/bootstrap/scss/utilities/_spacing';

.footer {
    background-color: $body-color;
    color: $white;

    a {
        color: $white;
        font-size: 1.1rem;
        font-weight: $font-weight-semibold;
    }

    @include media-breakpoint-up(lg) {
        a {
            color: $white;
            font-size: 1rem;
        }
    }

    h2 {
        font-size: 1.35rem;
        font-weight: $font-weight-semibold;

        @extend .pb-3;
        @extend .pt-2;
        @extend .mb-2;
    }

    .border-top-dark-sm {
        border-top: 2px solid lighten($body-color, 10%);
        margin-top: 2.5rem;
        padding-top: 1.5rem;
    }

    .border-top-dark-lg {
        border-top: 0;
    }

    @include media-breakpoint-up(md) {
        .border-top-dark-sm {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
        }

        .border-top-dark-lg {
            border-top: 2px solid lighten($body-color, 10%);
        }
    }

    .footer-social-nav {
        margin-top: 2rem;

        li {
            &.list-inline-item:not(:last-child) {
                margin-right: 0.5rem;
            }

            a.social {
                @include transition(opacity 300ms);
                @include retina-image('images/social-icons_2x.png', 309px, 62px);
                background-image: url('images/social-icons.png');
                background-position-y: top;
                background-size: 309px 62px;
                display: block;
                height: 0;
                opacity: 1;
                overflow: hidden;
                padding-top: rem-calc(31);
                width: rem-calc(31);

                &:hover {
                    background-position-y: bottom;
                }

                &.twitter {
                    width: rem-calc(30);
                }

                &.linkedin {
                    background-position-x: rem-calc(-51);
                    width: rem-calc(31);
                }

                &.facebook {
                    background-position-x: rem-calc(-107);
                    width: rem-calc(31);
                }

                &.youtube {
                    background-position-x: rem-calc(-160);
                    width: rem-calc(43);
                }

                &.instagram {
                    background-position-x: rem-calc(-223);
                    width: rem-calc(32);
                }

                &.pintrest {
                    width: rem-calc(30);
                }

                &.tiktok {
                    width: rem-calc(30);
                    background-position-x: rem-calc(-277);
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .footer-social-nav {
            margin-top: 1rem;
        }
    }

    .footer-logo {
        @extend .mb-3;
    }

    .footer-nav {
        li {
            display: block;
            @extend .mb-3;

            a {
                font-weight: $font-weight-bold;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .footer-nav {
            li {
                display: inline-block;
                margin-bottom: 0;

                &.list-inline-item:not(:last-child) {
                    margin-right: 2rem;
                }

                a {
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }

    .pay-unipol {
        background-color: $white;
        color: $body-color;
        display: inline-block;
        font-weight: bold;
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
    }

    @include media-breakpoint-up(md) {
        .pay-unipol {
            padding-left: 3rem;
            padding-right: 3rem;
            padding-bottom: 0.75rem;
            padding-top: 0.75rem;
        }
    }

    .footer__info > div:not(:nth-child(2))  {

        .border-top-dark-sm {
            border-color: transparent;
            margin-top: 0;
            padding-top: 0;
        }
    }
    .copyright{
        font-size: 0.8em;
    }
}

